import { CTA_TYPES } from "../Plugin/constants";

export const CTA_OPTIONS = [
  {
    label: "Long Button",
    value: CTA_TYPES.buttonLong,
  },
  {
    label: "Short Button",
    value: CTA_TYPES.buttonShort,
  },
];

/**
 * value label mapping required for select to display data properly
 */
export const CTA_OPTION_MAPPING = CTA_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

export const sayCustomBreakpoints = {
  breakpoints: {
    values: {
      xs: 0, // unused
      sm: 800,
      md: 1100, // unused
      lg: 1400, // unused
      xl: 1920, // unused
    },
  },
};
