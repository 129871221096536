export enum CTA_TYPES {
  buttonShort = "ButtonShort",
  buttonLong = "ButtonLong",
  fab = "FAB",
  icon = "Icon",
}

export enum ERROR_REASON {
  WINDOW_CLOSED = "WINDOW_CLOSED",
  AUTH_CODE_FAILED = "AUTH_CODE_FAILED",
  INVALID_CODE = "INVALID_CODE",
  ID_NOT_FOUND = "ID_NOT_FOUND",
  GENERIC = "GENERIC",
  DISALLOW = "DISALLOW",
}
