import {
  Button,
  HookCheckbox,
  HookSelect,
  HookTextField,
  Text,
} from "@asayinc/component-library";

import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { FormProvider, FieldValues, useForm } from "react-hook-form";
import { CTA_OPTIONS, CTA_OPTION_MAPPING } from "../constants";
import { IError } from "../Plugin/types";
(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") &&
  import("../Plugin/plugin");
/**
 * form for generating a auth with say cta for testing purposes
 */

const TestSite = () => {
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState<IError | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const urlParams = new window.URLSearchParams(window.location.search);
      const myParam = urlParams.get("env");
      const url =
        myParam === "production"
          ? "https://assets.saytechnologies.com/plugins/auth_with_say.js"
          : "https://assets.say.rocks/plugins/auth_with_say_staging.js";
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    mode: "all",
  });

  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const sendUserInfo = watch("sendUserInfo");

  /**
   * set authCode in state if exists, otherwise set error
   */
  const callback = ({
    authCode,
    error,
  }: {
    authCode?: string;
    error?: IError;
  }) => {
    if (authCode) {
      setAuthCode(authCode);
      setError(null);
    } else if (error) {
      setError(error);
      setAuthCode("");
    }
  };

  /**
   * generate cta with form data on submit
   */
  const onSubmit = (data: FieldValues) => {
    window.authWithSay({
      callback,
      clientId: data.clientId,
      elementId: "placeme",
      ctaType: data.ctaType,
      userInfo: sendUserInfo ? data.userInfo : undefined,
    });
  };

  return (
    <Box maxWidth={500} margin="0 auto">
      <header className="App-header">
        <Text variant="h1" sx={{ mb: 5 }}>
          Auth with Say.
        </Text>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} mb={10}>
            <HookSelect
              valueMapping={CTA_OPTION_MAPPING}
              label="CTA Type"
              menuTitle="CTA Type"
              size="medium"
              name="ctaType"
              options={CTA_OPTIONS}
              rules={{
                required: "CTA Type is required",
              }}
            />
            <HookTextField
              sx={{ width: "100%" }}
              name="clientId"
              label="Client ID"
              id="clientId"
              inputSize="small"
              rules={{
                required: {
                  value: true,
                  message: "Client ID is required.",
                },
              }}
            />
            example: ci_0ed67a007ce790968d2009eef6929866
            <HookCheckbox
              name="sendUserInfo"
              data-testid="sendUserInfo-checkbox"
              label="Send User Info"
            />
            {sendUserInfo ? (
              <>
                <HookTextField
                  sx={{ width: "100%" }}
                  name="userInfo.firstName"
                  label="First Name"
                  id="firstName"
                  inputSize="small"
                  rules={{
                    required: {
                      value: true,
                      message: "First Name is required.",
                    },
                  }}
                />
                <HookTextField
                  sx={{ width: "100%" }}
                  name="userInfo.lastName"
                  label="Last Name"
                  id="lastName"
                  inputSize="small"
                  rules={{
                    required: {
                      value: true,
                      message: "Last Name is required.",
                    },
                  }}
                />
                <HookTextField
                  sx={{ width: "100%" }}
                  name="userInfo.email"
                  label="Email"
                  id="email"
                  inputSize="small"
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required.",
                    },
                  }}
                />
              </>
            ) : null}
            <Button
              disabled={!isValid}
              type="submit"
              data-testid="btn-create-launch"
            >
              Create Launcher
            </Button>
          </Stack>
        </form>
      </FormProvider>
      <div id="placeme"></div>
      <Box mt={2}>
        Auth Code Callback Response:{" "}
        <Text variant="h5" component="p">
          - {authCode || "no token"}
        </Text>
      </Box>
      <Box mt={2}>
        Error:{" "}
        <Text variant="h5" component="p" category="error">
          - {error ? JSON.stringify(error) : "no error"}
        </Text>
      </Box>
    </Box>
  );
};

export default TestSite;
