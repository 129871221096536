import React from "react";
import ReactDOM from "react-dom/client";
import App from "./TestSite";
import reportWebVitals from "./reportWebVitals";
import { sayCustomBreakpoints } from "./constants";
// Say
import { Provider as SayProvider } from "@asayinc/component-library";
// Material
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SayProvider customBreakpoints={sayCustomBreakpoints}>
      <CssBaseline />
      <App />
    </SayProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
